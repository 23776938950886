<template>
  <div class="login-container">
    <div class="left-panel">
      <img class="login-logo" src="../assets/noahai.svg" /> 
      
    </div>
    <div class="right-panel"> 
      <div class="management-text"> NOAH ARTIFICIAL INTELLIGENCE</div>
      <form @submit.prevent="login"> 
        <div class="input-areas">
          <input id="email" v-model="form.email" type="email" placeholder="E-mail" required />
          <input id="password" v-model="form.password" type="password" placeholder="Password" required />
        </div>
        <div v-if="message" :class="messageClass">{{ message }}</div> 
        <img 
          src="../assets/icon/login-icon.svg" 
          alt="Login Icon" 
          class="submit-img" 
          @click="login"
        />
      </form> 
      <div class="info-text">
        <p>Short accsess / Hızlı erişim:</p> 
      </div>
      <div class="button-group">
        <button
          class="area-btn"
          v-for="area in areas"
          :key="area.name"
          :style="{ backgroundColor: area.color }"
          @click="openLink(area.url)"
        >
          {{ area.name }}
        </button>
      </div>
    </div> 
  </div>
</template>


<script>
import axios from 'axios'; 

export default { 
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      message: '',
      messageClass: 'error',
      areas: [
        { name: 'NOAH PROMO', color: '#023032', url: 'https://noahpmu.com' },
        { name: 'NOAH SHOP', color: '#106e35', url: 'https://shop.noahpmu.com' }, 
        { name: 'REFIKA BEAUTY', color: '#065959', url: 'https://refikaguzellik.com' },
        { name: 'REFIKA BRANCH', color: '#353535', url: 'https://subeler.refikaguzellik.com' },
        { name: 'NRS', color: '#023032', url: 'https://nrs.noahpmu.com' },
      ],
    };
  },
  methods: { 
    async login() {
      try {
        const response = await axios.post('/noah/login', this.form);
        console.log('Login successful:', response.data);
        localStorage.setItem('token', response.data.token);
        this.message = 'Login successful';
        this.messageClass = 'success';
      } catch (error) {
        console.error('Login error:', error.response ? error.response.data : error.message);
        this.message = 'Login failed. Please check your credentials.';
        this.messageClass = 'error';
      }
    },
    openLink(url) {
      window.open(url, '_blank');
    },
  },
};
</script>


<style scoped>
.login-container {
  display: flex;
  min-height: 85vh;
  justify-content: center;
  align-items: center; 
  flex-wrap: wrap;
}

.left-panel {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
 
.warn-icon {
  max-width: 5em; margin: 2em 0 2em 0; cursor: pointer; opacity: 0.4;
}
.warn-icon:hover {
  transform: scale(1.2);
  opacity: 1;
  transition: transform 0.2s ease-in-out;
}

.login-logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 75px;
} 
.management-text {
  margin-top: 32px;
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: 300;
  color: #474747;
  text-align: left;
}

.right-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  min-width: 300px;
}

.input-areas {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.submit-img {
  cursor: pointer;
  max-width: 4.80em;
  opacity: 0.5;
}

.submit-img:hover {
  opacity: 1;
}

.message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
}

.error { 
  color: #d00000;
  margin-bottom: 1em;
}

.success { 
  color: #1ed760;
  margin-bottom: 1em;
}
.info-text {
  margin: 20px 0 0 0;
  font-size: 1.2em;
  text-align: left;
  color: #474747;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 5em;

}

.area-btn {
  flex: 1;
  padding: 10px 5px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  filter: grayscale(100%);
  opacity: 0.5;
}

.area-btn:hover { 
  filter: grayscale(0%);
  opacity: 1;
}

@media (max-width: 1080px) {
  .left-panel {
    width: 100%;
    height: 120px;
    padding: 10px;
    border-radius: 0; 
    background-color: transparent;
  }
  .submit-btn { 
    align-self: flex-end;  margin-left: auto; 
  }
  .login-logo {
    max-height: 220px;
    margin-bottom: 0;
  }

  .management-text {
    font-size: 14px;
    margin-top: 40px;
  }

  .right-panel {
    width: 100%;
    padding: 10px;
  }

  .title {
    font-size: 2em;
    display: none;
  }
  .area-btn { 
  filter: grayscale(0%); 
  opacity: 1;
}
}
</style> 